.centerpiece {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px); /* Adjust this value based on your navbar height */
    text-align: center;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .centerpiece h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .centerpiece p {
    font-size: 1.5rem;
    color: var(--text-color);
  }