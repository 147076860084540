/* src/App.css */
:root {
  --background-color: white;
  --text-color: black;
}

[data-theme='dark'] {
  --background-color: black;
  --text-color: white;
}

html, body {
  height: 100%;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}



