/* src/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    flex-wrap: wrap;
    border-bottom: 1px solid var(--text-color);
    height: 4rem;
    align-items: center;
    padding: 1rem;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    flex: 1;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .navbar-links li {
    font-size: 1rem;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: var(--text-color);
  }

  .navbar-template {
    margin-left: auto;
  }
  
  
  .navbar-template .template-button {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    /* visibility: hidden; */
  }
  
  .toggle-button {
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: var(--text-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .toggle-button:hover {
    opacity: 0.8;
  }